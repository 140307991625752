import { useEffect, useCallback, useState, useContext } from "react"

import gsap from "gsap"
import styled from "styled-components"

import colors from "styles/colors"
import media from "styles/media"
import {
  registerLoaderCallback,
  unregisterLoaderCallback,
} from "utils/Loader/LoaderUtils"
import useMedia from "utils/useMedia"

import { PopUpContext } from "./Providers"

const PATHSData = {
  desktop: {
    initial: "M0 0C0 0 502.495 0 830 0C1157.51 0 1660 0 1660 0V1070H0V0Z",
    topMid:
      "M0 150C0 150 502.495 0 830 0C1157.51 0 1660 150 1660 150V1070H0V150Z",
    bottomMid:
      "M0 0C0 0 502.495 0 830 0C1157.51 0 1660 0 1660 0V1728.64C1660 1728.64 1159.12 1895 830 1895C500.881 1895 0 1728.64 0 1728.64V0Z",
    bottomMidMore:
      "M0 0C0 0 502.495 0 830 0C1157.51 0 1660 0 1660 0V1122.48C1660 1122.48 1159.12 1300 830 1300C500.881 1300 0 1122.48 0 1122.48V0Z",
  },
  tablet: {
    initial: "M0 0C0 0 309.973 0 512 0C714.027 0 1024 0 1024 0V1601H0V0Z",
    topMid:
      "M0 172.065C0 172.065 309.973 0 512 0C714.027 0 1024 172.065 1024 172.065V1739H0V172.065Z",
    bottomMid:
      "M1024 1568.74C1024 1568.74 707.027 1669 505 1669C302.973 1669 -0.000113305 1568.74 -0.000113305 1568.74L2.38383e-05 0.00015462L1024 0.000244141L1024 1568.74Z",
    bottomMidMore:
      "M1024 1568.74C1024 1568.74 714.027 1741 512 1741C309.973 1741 -0.000107011 1568.74 -0.000107011 1568.74L3.01327e-05 0.000105982L1024 0.000195503L1024 1568.74Z",
  },
  mobile: {
    initial: "M0 0C0 0 113.515 0 187.5 0C261.485 0 375 0 375 0V812H0V0Z",
    topMid: "M0 50C0 50 113.515 0 187.5 0C261.485 0 375 50 375 50V862H0V50Z",
    bottomMid:
      "M375 812C375 812 255.485 842.5 181.5 842.5C107.515 842.5 2.6664e-06 812 2.6664e-06 812L7.36537e-05 0.000272392L375 0.000305176L375 812Z",
    bottomMidMore:
      "M375 812C375 812 261.485 862 187.5 862C113.515 862 4.37114e-06 812 4.37114e-06 812L7.53584e-05 2.82516e-05L375 6.10352e-05L375 812Z",
  },
}

export default function PageLoader() {
  const [wrapperEl, setWrapperEl] = useState<HTMLDivElement | null>(null)
  const [blueEl, setBlueEl] = useState<SVGElement | null>(null)
  const [pathRef, setPathRef] = useState<SVGPathElement | null>(null)
  const [logoEl, setLogoEl] = useState<HTMLDivElement | null>(null)
  const { setCanStart } = useContext(PopUpContext)

  const [swipeTl, setSwipeTl] = useState<GSAPTimeline | null>(null)
  const PATHS = useMedia(
    PATHSData.desktop,
    PATHSData.desktop,
    PATHSData.tablet,
    PATHSData.mobile
  )
  const viewBox = useMedia(
    "0 0 1660 1070",
    "0 0 1660 1070",
    "0 0 1024 1601",
    "0 0 375 862"
  )

  useEffect(() => {
    if (wrapperEl && blueEl && logoEl && pathRef) {
      const swipe = gsap.timeline({
        paused: true,
        repeatRefresh: true,
      })

      swipe
        .to(blueEl, {
            immediateRender: false,
            duration: 0.5,
            y: 0,
            ease: "circ.out",
          }, 0)
        .to(pathRef, {
            morphSVG: PATHS.topMid,
            ease: "back",
            duration: 0.2,
          }, 0)
        .to(pathRef, {
            morphSVG: PATHS.initial,
            ease: "none",
            duration: 0.2,
          }, 0.3)
        .to(pathRef, {
            morphSVG: PATHS.bottomMid,
            ease: "none",
            duration: 0,
          }, 0.5)
        .to(blueEl, {
            ease: "circ.inOut",
            y: "-120%",
            duration: 0.4,
          }, 0.6)
        .to(pathRef, {
            morphSVG: PATHS.bottomMidMore,
            ease: "back",
            duration: 0.3,
          }, 0.7)

      swipe.set(
        wrapperEl,
        {
          background: "transparent",
        },
        0.45
      )

      swipe.set(
        logoEl,
        {
          opacity: 0,
        },
        0.45
      )

      setSwipeTl(swipe)

      return () => {
        swipe.kill()
      }
    }
  }, [wrapperEl, blueEl, logoEl, setSwipeTl, pathRef, PATHS])

  useEffect(() => {
    if (logoEl && swipeTl) {
      const tl = gsap.timeline({
        onComplete: () => {
          swipeTl.play()
        },
      })
      const targetFill = ".clip-fill_rect"

      tl.to(logoEl, {
          immediateRender: false,
          duration: 0.5,
          opacity: 1,
        }, 0).fromTo(targetFill, { y: "100%" },
        { y: "0", duration: 1.5, ease: "circ.in" }, 0.5)

      return () => {
        tl.kill()
        swipeTl.kill()
      }
    }
  }, [logoEl, swipeTl])

  const end = useCallback(() => {
    if (swipeTl && blueEl && wrapperEl) {
      const onComplete = () => {
        gsap.to([blueEl], {
          duration: 0,
          opacity: 0,
          y: "100%",
          onComplete: () => {
            setCanStart(true)
          },
        })
        swipeTl.eventCallback("onComplete", null)
      }

      swipeTl.eventCallback("onComplete", onComplete)
      swipeTl.repeat(0)
    }
  }, [swipeTl, blueEl, wrapperEl, setCanStart])

  useEffect(() => {
    // register a loader
    registerLoaderCallback({
      callback: end,
      duration: 1,
    })

    return () => {
      // clean up loader
      unregisterLoaderCallback(end)
    }
  }, [end])

  return (
    <Wrapper ref={ref => setWrapperEl(ref)}>
      <Blue
        ref={ref => setBlueEl(ref)}
        viewBox={viewBox}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="none"
      >
        <path ref={ref => setPathRef(ref)} d={PATHS.initial} fill="#3D50FC" />
      </Blue>
      <LogoWrap ref={ref => setLogoEl(ref)}>
        <svg
          viewBox="0 0 65 64"
          fill="#00000015"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            id="loader-logo"
            d="M17.8618 1.80504H53.073C38.2981 8.18336 26.0934 19.174 26.0934 34.7232C26.0934 46.7939 35.8694 56.5924 45.2423 62.1915H17.8618C17.8618 62.1915 0.00976562 54.4717 0.00976562 33.2417C0.00976562 11.2209 17.8618 1.80504 17.8618 1.80504ZM63.9916 62.1915H54.7461C47.2267 57.4346 42.1523 50.375 42.1523 42.9446C42.1523 33.4377 48.5471 24.6798 63.9916 21.7589V62.1915Z"
          />
          <clipPath id="clip-fill">
            <rect
              x="0"
              y="0"
              className="clip-fill_rect"
              width="65"
              height="64"
              fill="#3D50FC"
            />
          </clipPath>

          <use clipPath="url(#clip-fill)" href="#loader-logo" fill="#3D50FC" />
        </svg>
      </LogoWrap>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  background: ${colors.white};
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 2147483639; //one more than the cookie policy thing
  pointer-events: none;
`

const Blue = styled.svg`
  z-index: 10;
  position: absolute;

  transform: translateY(100%);
  left: 0;
  width: 100vw;
  height: 100vh;
`

const LogoWrap = styled.div`
  position: absolute;
  opacity: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  .clip-fill_rect {
    z-index: 100;
  }

  ${media.fullWidth} {
    width: 130px;
    height: 128px;
  }

  ${media.desktop} {
    width: 9.028vw;
    height: 8.889vw;
  }

  ${media.tablet} {
    width: 12.695vw;
    height: 12.5vw;
  }

  ${media.mobile} {
    width: 34.667vw;
    height: 34.133vw;
  }
`
