import LightmodeProvider, { Lightmode } from "./Lightmode"
import OpeningsProvider, { Openings } from "./Openings"
import PageContentProvider, { PageContentContext } from "./PageContent"
import PopUpProvider, { PopUpContext } from "./Popup"
import ScreenProvider, { ScreenContext } from "./Screen"
import ShowHamburgerProvider, { ShowHamburger } from "./ShowHamburger"
import TestimonialsProvider, { TestimonialsContext } from "./Testimonials"
import ThinBannerProvider, { ThinBannerContext } from "./ThinBanner"
import TranslateURLProvider, { TranslateURLContext } from "./TranslateURL"

interface Props {
  children: React.ReactNode
}

export default function Providers({ children }: Props) {
  return (
    <OpeningsProvider>
      <PageContentProvider>
        <TranslateURLProvider>
          <TestimonialsProvider>
            <PopUpProvider>
              <ThinBannerProvider>
                <LightmodeProvider>
                  <ShowHamburgerProvider>
                    <ScreenProvider>{children}</ScreenProvider>
                  </ShowHamburgerProvider>
                </LightmodeProvider>
              </ThinBannerProvider>
            </PopUpProvider>
          </TestimonialsProvider>
        </TranslateURLProvider>
      </PageContentProvider>
    </OpeningsProvider>
  )
}

export {
  Openings,
  ShowHamburger,
  Lightmode,
  ScreenContext,
  ThinBannerContext,
  PopUpContext,
  TestimonialsContext,
  PageContentContext,
  TranslateURLContext,
}
