import { createContext, useEffect, useMemo, useState } from "react"

import { graphql, useStaticQuery } from "gatsby"

export const TranslateURLContext = createContext<{
  allLocaleURLS: URLData[] | null
  setActiveContentfulID: (contentful_id: string) => void
}>({
  allLocaleURLS: null,
  setActiveContentfulID: () => null,
})

interface Props {
  children: React.ReactNode
}

interface URLData {
  contentful_id: string
  id: string
  node_locale: string
  urlSlug: string | null
}

export default function TranslateURLProvider({ children }: Props) {
  const [allLocaleURLS, setAllLocaleURLS] = useState<URLData[] | null>(null)
  const [activeContentfulID, setActiveContentfulID] = useState<string>("")

  const TranslateURLValues = useMemo(() => {
    return {
      setActiveContentfulID,
      allLocaleURLS,
    }
  }, [setActiveContentfulID, allLocaleURLS])

  const blogNewsURLS: Queries.BlogNewsURLSQuery = useStaticQuery(graphql`
    query BlogNewsURLS {
      allContentfulBlogOrNewsPost {
        nodes {
          contentful_id
          id
          node_locale
          urlSlug
        }
      }
      allContentfulLandingPage {
        nodes {
          contentful_id
          id
          urlSlug
          node_locale
        }
      }
    }
  `)

  useEffect(() => {
    if (activeContentfulID) {
      if (activeContentfulID === "reset") return setAllLocaleURLS(null)

      const filteredURL = [
        ...blogNewsURLS.allContentfulLandingPage.nodes,
        ...blogNewsURLS.allContentfulBlogOrNewsPost.nodes,
      ].filter(url => url.contentful_id === activeContentfulID)
      if (filteredURL.length > 0) {
        setAllLocaleURLS(filteredURL)
      }
    }
  }, [activeContentfulID, blogNewsURLS])

  return (
    <TranslateURLContext.Provider value={TranslateURLValues}>
      {children}
    </TranslateURLContext.Provider>
  )
}
