import { createContext, useMemo, useState } from "react"

export const ShowHamburger = createContext<{
  show: boolean
  setShow: React.Dispatch<React.SetStateAction<boolean>>
}>({
  show: true,
  setShow: () => true,
})

interface Props {
  children: React.ReactNode
}

export default function ShowHamburgerProvider({ children }: Props) {
  const [show, setShow] = useState<boolean>(true)

  const showHamburger = useMemo(() => {
    return { show, setShow }
  }, [show])

  return (
    <ShowHamburger.Provider value={showHamburger}>
      {children}
    </ShowHamburger.Provider>
  )
}
