import { createContext, useState, useMemo, useEffect } from "react"

import gsap from "gsap"

import colors from "styles/colors"

export const Lightmode = createContext<{
  lightmode: boolean
  setLightmode: React.Dispatch<React.SetStateAction<boolean>>
}>({
  lightmode: false,
  setLightmode: () => false,
})

interface Props {
  children: React.ReactNode
}

/**
 * Toggles the nav highlight mode
 */

export default function LightmodeProvider({ children }: Props) {
  const [lightmode, setLightmode] = useState<boolean>(false)

  const lightModeValues = useMemo(() => {
    return { lightmode, setLightmode }
  }, [lightmode])

  useEffect(() => {
    gsap.set("html", {
      backgroundColor: lightmode ? colors.lightMode : colors.darkMode,
    })
  }, [lightmode])

  return (
    <Lightmode.Provider value={lightModeValues}>{children}</Lightmode.Provider>
  )
}
