import { createContext, useMemo, useState } from "react"

export const Openings = createContext<{
  numberOfOpenings: number
  setNumberOfOpenings: React.Dispatch<React.SetStateAction<number>>
  numberOfLocations: number
  setNumberOfLocations: React.Dispatch<React.SetStateAction<number>>
}>({
  numberOfLocations: 0,
  setNumberOfLocations: () => 0,
  numberOfOpenings: 0,
  setNumberOfOpenings: () => 0,
})

interface Props {
  children: React.ReactNode
}

export default function OpeningsProvider({ children }: Props) {
  const [numberOfOpenings, setNumberOfOpenings] = useState(0)
  const [numberOfLocations, setNumberOfLocations] = useState(0)

  const openings = useMemo(() => {
    return {
      numberOfLocations,
      setNumberOfLocations,
      numberOfOpenings,
      setNumberOfOpenings,
    }
  }, [numberOfLocations, numberOfOpenings])

  return <Openings.Provider value={openings}>{children}</Openings.Provider>
}
