import { TranslationObj } from "types"
import { VisibilityRule, MarketoFormData } from "types/marketo"

export type Translation = Record<string, string | undefined>

export const isBrowser = () => typeof window !== "undefined"

export const translateSeo = (
  translationsJSON: string | null,
  title: string,
  description: string
) => {
  let translatedTitle = title
  let translatedDesc = description
  if (translationsJSON) {
    const translations: TranslationObj = JSON.parse(
      translationsJSON
    ) as TranslationObj

    translatedTitle = translations[title]
    translatedDesc = translations[description]
  }
  return { translatedTitle, translatedDesc }
}

export const addDebouncedEventListener = (
  element: Window | HTMLElement,
  event: string,
  callback: () => void,
  delay: number
) => {
  let timeout: NodeJS.Timeout

  const debouncedCallback = () => {
    clearTimeout(timeout)
    timeout = setTimeout(() => callback(), delay)
  }
  element.addEventListener(event, debouncedCallback)
}

export const vwToPx = (vw: number) => {
  if (isBrowser()) {
    const px = vw * (window.innerWidth / 100)
    return px
  }
  return 0
}

export const vhToPx = (vh: number) => {
  if (isBrowser()) {
    const px = vh * (window.innerHeight / 100)
    return px
  }
  return 0
}

/**
 * Add opacity to a Hex color. First, it converts the decimal opacity value into its equivalent in the range of 0-255, then it turns that value into hex and appends it to the color.
 * @param color - The hex color you want to add opacity to.
 * @param opacity - The decimal value for desired opacity.
 */
export const Opacity = (color: string, opacity: number) => {
  const valueInHexRange = ((opacity - 0) * (255 - 0)) / (1 - 0) + 0
  return (
    color + Number(Math.round(valueInHexRange)).toString(16)
  ).toUpperCase()
}

export const sleep = (ms: number) =>
  new Promise(resolve => {
    setTimeout(resolve, ms)
  })

export function pathnameMatches(pathA: string, pathB: string) {
  return pathA === pathB || pathA === `${pathB}/`
}

export const inDomainList = (email: string, domains: string[]) => {
  return domains
    .map(domain => {
      return new RegExp(`@${domain.replace(".", "\\.")}$`, "i")
    })
    .some(reDomain => {
      return reDomain.test(email)
    })
}

export const isIOS = () => {
  if (/iPad|iPhone|iPod/.test(navigator.userAgent)) {
    return true
  }
  return (
    navigator.maxTouchPoints &&
    navigator.maxTouchPoints > 2 &&
    navigator.userAgent.includes("MacIntel")
  )
}

export const isIpadOS = () => {
  return (
    navigator.maxTouchPoints &&
    navigator.maxTouchPoints > 2 &&
    navigator.userAgent.includes("MacIntel")
  )
}

export const fixIOSScroll = (form: HTMLFormElement) => {
  if (form) {
    if (isIOS() || isIpadOS()) {
      form.addEventListener(
        "focus",
        (e: FocusEvent) => {
          const target = e.target as HTMLInputElement
          target.scrollIntoView()
          window.scrollBy(0, -100)
        },
        true
      )
    }
  }
}

export const parseFormData = (
  e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
) => {
  const data = { [e.target.id]: removeAsteriskAndSpace(e.target.value) }

  return data
}

export const parseCheckData = (
  e: React.ChangeEvent<HTMLInputElement>,
  singleChecked: boolean
) => {
  if (singleChecked) {
    const data = { [e.target.id]: e.target.checked }

    return data
  }
  const data = { [e.target.name]: e.target.checked }

  return data
}

export const parseRadioData = (name: string, parentID: string) => {
  const data = { [parentID]: name }

  return data
}

export const removeAsteriskAndSpace = (str: string | null) => {
  return str?.replace(/\*/g, "").trim() ?? null
}

export const checkAllVisibilityRules = (
  visibilityRulesArr: VisibilityRule[],
  formData: MarketoFormData
) => {
  const result = visibilityRulesArr.map(rule => {
    return checkFormFieldVisibility(rule, formData)
  })

  return result
}

export const checkFormFieldVisibility = (
  visibilityRule: VisibilityRule,
  formData: MarketoFormData
) => {
  if (visibilityRule?.values) {
    const { values } = visibilityRule

    const cleanedDependency = removeAsteriskAndSpace(
      visibilityRule.subjectField
    )
    if (!cleanedDependency) return false
    const currentValue = formData[cleanedDependency]

    const currentCleaned = removeAsteriskAndSpace(
      String(currentValue ?? "not found")
    )
    if(values.indexOf(currentCleaned)>-1){
            return true;
     }
    return currentCleaned === values[0]
  }

  return true
}

export const removeTagsFromString = (message: string) => {
  const str = message.toString()
  if (!str.includes("<")) {
    return str
  }
  return str
    .replace(/(<([^>]+)>)/gi, "")
    .split(". ")
    .map((item, i) => {
      if (i === 1) {
        return ` (ex: ${item})`
      }
      return `${item}.`
    })
    .join("")
}

export const parseHTML = (html: string) => {
  const parser = new DOMParser()
  const doc = parser.parseFromString(html, "text/html")
  const elements = doc.body.children
  const parsedElements = []
  for (let i = 0; i < elements.length; i + 1) {
    parsedElements.push(elements[i])
  }
  return parsedElements
}

export const handleFallbackTranslation = (
  translationEl: Translation,
  key: string
) => {
  if (!translationEl[key]) {
    return key
  }
  return translationEl[key]
}
