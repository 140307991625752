import { ColorDictionary } from "types"

const colors = {
  black: "#000000",
  white: "#ffffff",
  darkMode: "#03081A",
  lightMode: "#F5F6FF",
  blue800: "#020626",
  blue700: "#080F4D",
  blue600: "#0B1673",
  blue500: "#162291",
  blue400: "#3D50FC",
  blue300: "#292F66",
  blue200: "#4D5499",
  blue100: "#7A83CC",
  blue50: "#AAB1F2",
  blue10: "#D9DDFF",
  destructive400: "#FF4040",
  destructive500: "#CC1E1E",

  magenta800: "#26021B",
  magenta700: "#4D0838",
  magenta600: "#731155",
  magenta500: "#991F74",
  magenta400: "#D80C9A",
  magenta300: "#8C3873",
  magenta200: "#B25998",
  magenta100: "#CC85B7",
  magenta50: "#E5B8D8",
  magenta10: "#F2DAEB",
  magentaLine: "#9219CB",

  turq800: "#032933",
  turq700: "#083F4D",
  turq600: "#115F73",
  turq500: "#1F8199",
  turq400: "#05A1C9",
  turq300: "#6BA4B2",
  turq200: "#85BECC",
  turq100: "#ACDAE5",
  turq50: "#C2E9F2",

  baby800: "#021126",
  baby700: "#08244D",
  baby600: "#0B3773",
  baby500: "#164991",
  baby400: "#1E78F5",
  baby300: "#294266",
  baby200: "#4D6C99",
  baby100: "#7A9CCC",
  baby50: "#AAC8F2",
  baby10: "#CDE0FA",

  coral800: "#260212",
  coral700: "#4D0825",
  coral600: "#73113B",
  coral500: "#991F54",
  coral400: "#EA5C81",
  coral300: "#8C385D",
  coral200: "#B35980",
  coral100: "#CC85A4",
  coral50: "#E6B8CB",

  seaFoam800: "#033333",
  seaFoam700: "#084D4D",
  seaFoam600: "#117373",
  seaFoam500: "#1F9999",
  seaFoam400: "#05E0E0",
  seaFoam300: "#6BACB2",
  seaFoam200: "#85CCCC",
  seaFoam100: "#ACE5E5",
  seaFoam50: "#D9FCFF",
  seaFoam10: "#EDFEFF",
  plum800: "#150333",
  plum700: "#22084D",
  plum600: "#371173",
  plum500: "#4E1F99",
  plum400: "#590BD3",
  plum300: "#866BB2",
  plum200: "#A085CC",
  plum100: "#C2ACE5",
  plum50: "#E8D9FF",

  purple800: "#1D0333",
  purple700: "#2E084D",
  purple600: "#471173",
  purple500: "#621F99",
  purple400: "#9224ED",
  purple300: "#8E62B2",
  purple200: "#AC85CC",
  purple100: "#CBACE5",
  purple50: "#EED9FF",

  // primary gradients
  primVert:
    "linear-gradient(193.56deg, #05A1C9 9.72%, #3D50FC 51.38%, #D80C9A 90.28%);",
  primHorz:
    "linear-gradient(103.56deg, #05A1C9 9.72%, #3D50FC 51.57%, #D80C9A 90.28%);",
  magBlueHorz: "linear-gradient(75.14deg, #D80C9A 10.48%, #3D50FC 89.52%);",
  magBlueVert: "linear-gradient(165.14deg, #D80C9A 10.48%, #3D50FC 89.52%);",
  turqBlueHorz: "linear-gradient(90deg, #05A1C9 0%, #3D50FC 100%);",
  turqBlueVert: "linear-gradient(180deg, #05A1C9 0%, #3D50FC 100%);",

  // secondary gradients

  turqSeaVert: "linear-gradient(90deg, #05E0E0 0%, #05A1C9 99%);",
  turqSeaHorz: "linear-gradient(360deg, #05E0E0 0%, #05A1C9 99%);",
  blueBabyHorz: "linear-gradient(90deg, #1E78F5 0%, #3D50FC 99%);",
  blueBabyVert: "linear-gradient(360deg, #1E78F5 0%, #3D50FC 99%);",
  plumHorz: "linear-gradient(90deg, #9224ED 0%, #590BD3 99%);",
  plumVert: "linear-gradient(0deg, #9224ED 0%, #590BD3 99%);",
  magCoralHorz: "linear-gradient(90deg, #EA5C81 0%, #D80C9A 99%);",
  magCoralVert: "linear-gradient(0deg, #EA5C81 0%, #D80C9A 99%);",
} as const

export const colorDictionary: ColorDictionary = {
  Blog: {
    10: colors.blue10,
    50: colors.blue50,
    200: colors.blue200,
    400: colors.blue400,
    500: colors.blue500,
    600: colors.blue600,
    800: colors.blue800,
    gradient: colors.blueBabyHorz,
  },
  People: {
    10: colors.blue10,
    50: colors.blue50,
    200: colors.blue200,
    400: colors.blue400,
    500: colors.blue500,
    600: colors.blue600,
    800: colors.blue800,
    gradient: colors.blueBabyHorz,
  },
  Event: {
    10: colors.magenta10,
    50: colors.magenta50,
    200: colors.magenta200,
    400: colors.magenta400,
    500: colors.magenta500,
    600: colors.magenta600,
    800: colors.magenta800,
    gradient: colors.magBlueHorz,
  },
  News: {
    10: colors.turq100,
    50: colors.turq50,
    200: colors.turq200,
    400: colors.turq400,
    500: colors.turq500,
    600: colors.turq600,
    800: colors.turq800,
    gradient: colors.turqBlueHorz,
  },
}

export default colors
