import { createContext, useMemo, useState } from "react"

export const ThinBannerContext = createContext<{
  show: boolean
  setShow: React.Dispatch<React.SetStateAction<boolean>>
}>({
  show: true,
  setShow: () => true,
})

interface Props {
  children: React.ReactNode
}

export default function ThinBannerProvider({ children }: Props) {
  const [show, setShow] = useState<boolean>(true)

  const showThinBanner = useMemo(() => {
    return { show, setShow }
  }, [show])

  return (
    <ThinBannerContext.Provider value={showThinBanner}>
      {children}
    </ThinBannerContext.Provider>
  )
}
