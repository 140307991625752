import { createContext, useEffect, useMemo, useState } from "react"

import { graphql, useStaticQuery } from "gatsby"

export const PageContentContext = createContext<{
  activePageContent:
    | Queries.MainPageQuery["allContentfulMainPage"]["nodes"]
    | null
  setActivePageContent: (
    page: Queries.MainPageQuery["allContentfulMainPage"]["nodes"] | null
  ) => void
  activePage: string
  setActivePage: (url: string) => void
}>({
  activePageContent: null,
  setActivePageContent: () => null,
  activePage: "",
  setActivePage: () => "",
})

interface Props {
  children: React.ReactNode
}

export default function PageContentProvider({ children }: Props) {
  const [activePageContent, setActivePageContent] = useState<
    Queries.MainPageQuery["allContentfulMainPage"]["nodes"] | null
  >(null)
  const [activePage, setActivePage] = useState<string>("")

  const PageValues = useMemo(() => {
    return {
      activePageContent,
      setActivePageContent,
      activePage,
      setActivePage,
    }
  }, [activePageContent, setActivePageContent, activePage, setActivePage])

  const pageData: Queries.MainPageQuery = useStaticQuery(graphql`
    query MainPage {
      allContentfulMainPage {
        nodes {
          contentful_id
          pageName
          node_locale
          videoCarousel {
            carouselName
            videoEmbeds {
              contentful_id
              videoName
              youtubeVideoId
              posterImage {
                ...ContentfulImageQuery
              }
              buttonText
            }
          }
          customCta {
            node_locale
            homepage
            buttons {
              buttonText
              buttonIcon
              lightMode
              contentful_id
              typeOverride
              url
            }
            title
            text {
              text
            }
            smallImage {
              description
              contentful_id
              title
              url
              gatsbyImageData(formats: WEBP, placeholder: BLURRED, quality: 90)
            }
            soloImage {
              images {
                description
                contentful_id
                title
                gatsbyImageData(
                  formats: WEBP
                  placeholder: BLURRED
                  quality: 90
                )
              }
              contentful_id
              imageUrl
            }
          }
        }
      }
    }
  `)

  useEffect(() => {
    if (activePage) {
      const allPageData = pageData.allContentfulMainPage.nodes.filter(page => {
        return activePage.includes(page.pageName ?? "zzzzzz")
      })
      setActivePageContent(allPageData)
    }
  }, [pageData, activePage])

  return (
    <PageContentContext.Provider value={PageValues}>
      {children}
    </PageContentContext.Provider>
  )
}
