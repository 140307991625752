import { createContext, useMemo, useState } from "react"

export const PopUpContext = createContext<{
  openPopUp: string
  setOpenPopUp: React.Dispatch<React.SetStateAction<string>>
  canStart: boolean
  setCanStart: React.Dispatch<React.SetStateAction<boolean>>
}>({
  openPopUp: "",
  setOpenPopUp: () => "",
  canStart: false,
  setCanStart: () => false,
})

interface Props {
  children: React.ReactNode
}

export default function PopUpProvider({ children }: Props) {
  const [openPopUp, setOpenPopUp] = useState<string>("")
  const [canStart, setCanStart] = useState<boolean>(false)

  const showPopUp = useMemo(() => {
    return { openPopUp, setOpenPopUp, canStart, setCanStart }
  }, [openPopUp, canStart])

  return (
    <PopUpContext.Provider value={showPopUp}>{children}</PopUpContext.Provider>
  )
}
